import React, { useState, useEffect } from 'react';
import {
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
} from '@mui/material';

const AddCustomerDialog = ({ token, open, handleClose, fetchCustomers }) => {
    const [name, setName] = useState('');
    const [nameError, setNameError] = useState(false);
    const [contactAddress, setAddress] = useState('');
    const [contactName, setContactName] = useState('');
    const [contactEmail, setContactEmail] = useState('');
    const [contactPhone, setContactPhone] = useState('');
    const [nameErrorMessage, setNameErrorMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const apiUrl = process.env.REACT_APP_API_HOST; // Access the environment variable

    useEffect(() => {
        // Reset state when the dialog is closed
        if (!open) {
            setName('');
            setNameError(false);
            setAddress('');
            setContactName('');
            setContactEmail('');
            setContactPhone('');
            setNameErrorMessage('');
            setErrorMessage('');
        }
    }, [open]);

    const handleNameChange = (event) => {
        const newName = event.target.value;
        setName(newName);
        // Validate the name (e.g., minimum length)
        if (newName.length < 3) {
            setNameError(true);
            setNameErrorMessage('Name should be at least 3 characters');
        } else {
            setNameError(false);
            setNameErrorMessage('');
        }
    };

    const handleAddressChange = (event) => {
        const newAddress = event.target.value;
        setAddress(newAddress);
    };

    const handleContactNameChange = (event) => {
        const newContactName = event.target.value;
        setContactName(newContactName);
    };

    const handleContactEmailChange = (event) => {
        const newContactEmail = event.target.value;
        setContactEmail(newContactEmail);
    };

    const handleContactPhoneChange = (event) => {
        const newContactPhone = event.target.value;
        setContactPhone(newContactPhone);
    };

    const handleAdd = () => {
        if (name.length >= 3) {
            // Perform add action or API call here
            handleSave();
        } else {
            setNameError(true);
            setNameErrorMessage('Name should be at least 3 characters');
        }
    };

    const handleSave = () => {
        const customerData = {
            name: name,
            contactAddress: contactAddress,
            contactName: contactName,
            contactEmail: contactEmail,
            contactPhone: contactPhone
        };

        fetch(apiUrl + '/api/admin/customer', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                // Add any other headers needed by your API
            },
            body: JSON.stringify(customerData),
        })
            .then(response => {
                if (response.ok) {
                    // Customer created successfully
                    fetchCustomers();
                    handleClose(); // Close the modal after successful creation
                    // You might want to trigger a refresh of customer data here if needed
                } else {
                    setErrorMessage('Failed to create customer. Please try again.'); // Set error message

                    // Handle errors, like validation errors or server issues
                    // You might display an error message to the user
                }
            })
            .catch(error => {
                // Handle network errors or other issues with the request
                // Display an error message or handle the error appropriately
            });
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Add New Customer</DialogTitle>
            <DialogContent style={{ width: '400px' }}>
                {errorMessage && (
                    <Typography variant="body2" color="error" gutterBottom>
                        {errorMessage}
                    </Typography>
                )}

                <TextField margin="normal" label="Name" fullWidth value={name} onChange={handleNameChange} error={nameError} helperText={nameErrorMessage} />

                <TextField margin="normal" label="Contact Address" fullWidth value={contactAddress} onChange={handleAddressChange} />
                <TextField margin="normal" label="Contact Name" fullWidth value={contactName} onChange={handleContactNameChange} />
                <TextField margin="normal" label="Contact Email" fullWidth value={contactEmail} onChange={handleContactEmailChange} />
                <TextField margin="normal" label="Contact Phone" fullWidth value={contactPhone} onChange={handleContactPhoneChange} />


            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleAdd} variant="contained" color="secondary">
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddCustomerDialog;
