import React, { useState, useEffect } from 'react';
import { Paper, Typography, List, ListItem, ListItemText, IconButton } from '@mui/material';

// Icons
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';

import AddCustomerUserDialog from './dialogs/AddCustomerUserDialog';

// app constants
const apiUrl = process.env.REACT_APP_API_HOST; // Access the environment variable

const Users = ({ customer, token }) => {

    const [customerUsers, setCustomerUsers] = useState([]);
    const [isCreateUserDialogOpen, setCreateUserDialogOpen] = useState(false);

    const handleAddUserDialogOpen = () => { setCreateUserDialogOpen(true); };
    const handleAddUserDialogClose = () => { setCreateUserDialogOpen(false); };

    // Function to fetch the customer user list
    const fetchCustomerUsers = async () => {
        fetch(apiUrl + '/api/customer/' + customer.key + '/users', {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setCustomerUsers(data); // Assuming the response data is an array of customer users
            })
            .catch(error => {
                console.error('Error fetching customers:', error);
            });
    };

    useEffect(() => {
        fetchCustomerUsers(); // Call the API function
    }, []); // Empty dependency array ensures the API call runs only once on component mount

    const handleRefresh = () => {
        fetchCustomerUsers();
    }

    const handleAddCustomerUser = () => {
        handleAddUserDialogOpen()
    }

    return (
        <div>
            <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
                <Typography gutterBottom style={{ textAlign: 'right', color: '#777' }}>
                    Users
                    <IconButton onClick={handleAddUserDialogOpen} color="inherit">
                        <AddIcon />
                    </IconButton>
                    <IconButton onClick={handleRefresh} color="inherit">
                        <RefreshIcon />
                    </IconButton>
                </Typography>
                <hr style={{ color: '#600' }} /> {/* Added horizontal line */}
                <List>
                    {customerUsers.map((user, index) => (
                        <React.Fragment key={user.key}>
                            <ListItem key={user.key}>
                                <ListItemText primary={user.username} secondary={user.email} />
                            </ListItem>
                            <hr style={{ color: '#600' }} /> {/* Added horizontal line */}
                        </React.Fragment>
                    ))}
                </List>
            </Paper>
            <AddCustomerUserDialog customer={customer} token={token} open={isCreateUserDialogOpen} handleClose={handleAddUserDialogClose} fetchUsers={fetchCustomerUsers} />

        </div>
    );
};

export default Users;
