// Login.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Typography, Container, Box } from '@mui/material'; // Import Material-UI components

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false); // State to manage login status
  const [error, setError] = useState(null); // State to manage error messages
  const [token, setToken] = useState(''); // Initialize token state

  const apiUrl = process.env.REACT_APP_API_HOST; // Access the environment variable
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(apiUrl + '/api/auth/signin', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      if (response.ok) {
        setIsLoggedIn(true); // Set isLoggedIn to true upon successful login

        const data = await response.json();
        const authToken = data.token;
        const roles = data.roles;
        setToken(authToken);

        if (roles.includes('ROLE_ADMIN')) {
          window.location.href = `/admin-dashboard?token=${authToken}`;
        } else {
          window.location.href = `/dashboard?token=${authToken}&customerId=${data.customerId}`;
        }

        //        navigate('/dashboard');
      } else {
        const data = await response.json();
        setError(data.message); // Set the error message received from the API
        alert('Login failed. Please try again.');
      }
    } catch (error) {
      setError('An error occurred. Please try again later.');
      console.error('Error:', error);
    }

    // Reset form fields after handling login
    setUsername('');
    setPassword('');
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="h4" component="h1">
          Login
        </Typography>
        <Box component="form" sx={{ mt: 3 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="password"
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            type="button"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleLogin}
          >
            Login
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default Login;
