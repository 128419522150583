// React Imports
import React, { useEffect, useState } from 'react';

// Material-UI Imports
import { Toolbar, Typography, List, ListItem, ListItemText, Divider, IconButton, TextField, } from '@mui/material';
import { makeStyles } from '@mui/styles';

// Icons
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import ClearIcon from '@mui/icons-material/Clear';

// Additional Components
import AddCustomerDialog from './dialogs/AddCustomerDialog';
import Customer from './Customer';

// Custom styles for the CustomerList component
const useStyles = makeStyles({
    listItem: {
        backgroundColor: '#f0f0f0',
        borderRadius: '8px',
        marginBottom: '8px',
        '&:hover': {
            backgroundColor: '#e0e0e0',
        },
    },

    mainDiv: {
        paddingTop: '20px',
    },
});

// app constants
const apiUrl = process.env.REACT_APP_API_HOST; // Access the environment variable


const CustomerList = ({ token }) => {
    const classes = useStyles();

    const [customers, setCustomers] = useState([]);

    // constants for the CreateCustomerDialog
    const [isCreateCustomerDialogOpen, setCreateCustomerDialogOpen] = useState(false);
    const handleAddCustomerDialogOpen = () => { setCreateCustomerDialogOpen(true); };
    const handleAddCustomerDialogClose = () => { setCreateCustomerDialogOpen(false); };

    // Constants for the selected customer
    const [selectedCustomer, setSelectedCustomer] = useState(null); // Track selected customer
    const handleCustomerClick = (customer) => {
        if (selectedCustomer === customer) {
            setSelectedCustomer(null); // Deselect customer if already selected
            return;
        }
        setSelectedCustomer(customer); // Set the selected customer when clicked
    };

    // Constants for the search bar
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredCustomers, setFilteredCustomers] = useState([]);
    const handleSearch = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchQuery(query);
        const filtered = customers.filter((customer) =>
            customer.name.toLowerCase().includes(query)
        );
        setFilteredCustomers(filtered);
    };

    // Constants for the refresh button
    const handleRefresh = () => {
        fetchCustomers();
        handleSearch({ target: { value: searchQuery } });
    };


    const fetchCustomers = () => {
        fetch(apiUrl + '/api/admin/customer', {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setCustomers(data);
            })
            .catch(error => {
                console.error('Error fetching customers:', error);
            });

    }

    useEffect(() => {
        if (token) {
            fetchCustomers()
        }
    }, [token]);

    return (
        <div className={classes.mainDiv} >
            <Toolbar>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    Customers
                </Typography>
                <TextField
                    label="Search by customer"
                    variant="outlined"
                    size="small"
                    value={searchQuery}
                    onChange={handleSearch}
                    style={{ marginRight: '10px', marginLeft: '20px' }}
                />
                <IconButton onClick={() => setSearchQuery('')} color="inherit">
                    <ClearIcon />
                </IconButton>
                <IconButton onClick={handleAddCustomerDialogOpen} color="inherit">
                    <AddIcon />
                </IconButton>
                <IconButton onClick={handleRefresh} color="inherit">
                    <RefreshIcon />
                </IconButton>
            </Toolbar>

            <List>
                {(searchQuery ? filteredCustomers : customers).map((customer) => (
                    <React.Fragment key={customer.key}>
                        <ListItem
                            className={classes.listItem}
                            selected={selectedCustomer === customer}
                            onClick={() => handleCustomerClick(customer)} >
                            <ListItemText
                                primary={customer.name}
                                secondary={`ID: ${customer.key} - Created at: ${new Date(customer.createdAt).toLocaleDateString()}`}
                            />
                        </ListItem>
                        {selectedCustomer === customer && <Customer customer={customer} token={token} />} {/* Render Customer component when selected */}
                        <Divider />
                    </React.Fragment>
                ))}
            </List>

            <AddCustomerDialog token={token} open={isCreateCustomerDialogOpen} handleClose={handleAddCustomerDialogClose} fetchCustomers={fetchCustomers} />

        </div >
    );
};

export default CustomerList;
