import React, { useState, useEffect } from 'react';
import {
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
} from '@mui/material';

const AddCustomerDriverDialog = ({ customer, token, open, handleClose, fetchDrivers }) => {
    const [name, setName] = useState('');
    const [nameErrorMessage, setNameErrorMessage] = useState('');
    const [responseErrorMessage, setResponseErrorMessage] = useState(''); // State to manage error messages

    const apiUrl = process.env.REACT_APP_API_HOST; // Access the environment variable

    useEffect(() => {
        // Reset state when the dialog is closed
        if (!open) {
            setName('');
            setNameErrorMessage('');
            setResponseErrorMessage('');

        }
    }, [open]);

    const handleNameChange = (event) => {
        if (event.target.value.length < 3) {
            setNameErrorMessage('Name should be at least 3 characters');
        } else {
            setNameErrorMessage('');
        }
        const newName = event.target.value;
        setName(newName);
    };


    const canSubmit = () => {
        return nameErrorMessage === '';
    }

    const handleAdd = () => {
        if (canSubmit()) {
            // Perform add action or API call here
            handleSave();
        } else {
            setNameErrorMessage('Name should be at least 3 characters');
        }
    };


    const handleSave = () => {
        const driverData = {
            name: name,
        };

        fetch(apiUrl + '/api/customer/' + customer.key + '/drivers', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                // Add any other headers needed by your API
            },
            body: JSON.stringify(driverData),
        }).then(response => {
            console.log('status: ' + response.status);

            if (response.ok) {
                // Customer created successfully
                fetchDrivers();
                handleClose();
            } else if (response.status === 409) {
                setResponseErrorMessage('Could not create driver.  There was a conflict'); // Set error message
            } else {
                console.log('status: ' + response.status);
                setResponseErrorMessage('Could not create driver. Please try again.'); // Set error message
            }

        }).catch(error => {
            console.log('general error');

            // Handle network errors or other issues with the request
            // Display an error message or handle the error appropriately
        });
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Add New Driver</DialogTitle>
            <DialogContent style={{ width: '300px' }}>
                {responseErrorMessage !== '' && (
                    <Typography variant="body2" color="error" gutterBottom>
                        {responseErrorMessage}
                    </Typography>
                )}

                <TextField margin="normal" required fullWidth id="name" label="Name" value={name} onChange={handleNameChange} helperText={nameErrorMessage} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleAdd} variant="contained" color="secondary" disabled={!canSubmit()}>
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddCustomerDriverDialog;
