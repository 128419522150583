import React from 'react';
import { Drawer, List, ListItem, ListItemText } from '@mui/material';

import CustomerSummary from './CustomerSummary';
import CustomerUsers from './CustomerUsers';
import CustomerDevices from './CustomerDevices';
import CustomerDeviceRegistrations from './CustomerDeviceRegistrations';
import CustomerDrivers from './CustomerDrivers';
import CustomerDrivingSessions from './CustomerDrivingSessions';

const Customer = ({ customer, token }) => {
    const options = ['Summary', 'Users', 'Devices', 'Drivers', 'Sessions', 'Device Registrations'];
    const [selectedOption, setSelectedOption] = React.useState('Summary');

    const handleOptionClick = (option) => {
        setSelectedOption(option);
    };

    const renderComponent = () => {
        switch (selectedOption) {
            case 'Summary':
                return <CustomerSummary customer={customer} />;
            case 'Users':
                return <CustomerUsers customer={customer} token={token} />;
            case 'Devices':
                return <CustomerDevices customer={customer} token={token} />;
            case 'Device Registrations':
                return <CustomerDeviceRegistrations customer={customer} token={token} />;
            case 'Drivers':
                return <CustomerDrivers customer={customer} token={token} />;
            case 'Sessions':
                return <CustomerDrivingSessions customer={customer} token={token} />;
            default:
                return null;
        }
    };

    return (
        <div style={{ display: 'flex', minHeight: '500px' }}>
            {/* Static Left Drawer */}
            <Drawer
                variant="permanent"
                anchor="left"
                PaperProps={{
                    style: {
                        background: '#00000000',
                        height: '100%',
                        position: 'relative',
                    },
                }}
            >
                <List>
                    {options.map((text, index) => (
                        <ListItem
                            button
                            key={text}
                            onClick={() => handleOptionClick(text)}
                            className={selectedOption === text ? 'selected' : ''}
                        >
                            <ListItemText primary={text} />
                        </ListItem>
                    ))}
                </List>
            </Drawer>

            {/* Main Content */}
            <div style={{ flexGrow: 1, padding: '20px' }}>
                {renderComponent()}
            </div>
        </div>
    );
};

export default Customer;
