import React from 'react';
import { Paper, Typography, Grid } from '@mui/material';

const CustomerSummary = ({ customer }) => {
    return (
        <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
            <Typography gutterBottom style={{ textAlign: 'right', color: '#777' }}>
                Customer Summary
            </Typography>
            <hr style={{ color: '#600' }} /> {/* Added horizontal line */}
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Typography variant="subtitle1">Customer Name</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography>{customer.name}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="subtitle1">Contact Name</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography>{customer.contactName}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="subtitle1">Contact Email</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography>{customer.contactEmail}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="subtitle1">Contact Phone</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography>{customer.contactPhone}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="subtitle1">Contact Address</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography>{customer.contactAddress}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="subtitle1">Active Since</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography>{new Date(customer.createdAt).toLocaleDateString('en-JP')}</Typography>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default CustomerSummary;
