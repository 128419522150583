import { createTheme } from '@mui/material/styles';

// Light Theme
const lightTheme = createTheme({
    palette: {
        // Customize light theme colors
        // For example:
        primary: {
            main: '#2196f3', // Change primary color
        },
        secondary: {
            main: '#f50057', // Change secondary color
        },
        // Other color options...
    },
    // Other theme customizations (typography, spacing, etc.)
});

// Dark Theme
const darkTheme = createTheme({
    palette: {
        // Customize dark theme colors
        // For example:
        primary: {
            main: '#1976d2', // Change primary color
        },
        secondary: {
            main: '#d81b60', // Change secondary color
        },
        text: {
            primary: '#000', // Default primary text color
            secondary: '#777', // Default secondary text color
            // Other text color variations if needed
        },

        // Other color options...
    },
    // Other theme customizations (typography, spacing, etc.)
});

export { lightTheme, darkTheme };
