import React, { useState, useEffect, useCallback } from 'react';
import { Paper, Typography, List, ListItem, ListItemText, IconButton } from '@mui/material';

// Icons
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import { QRCodeSVG } from 'qrcode.react';
// App environment variables
const apiUrl = process.env.REACT_APP_API_HOST; // Access the environment variable

const CustomerDeviceRegistrations = ({ customer, token }) => {
    const [customerRegistrations, setCustomerRegistrations] = useState([]);

    const fetchCustomerRegistrations = useCallback(async () => {
        fetch(apiUrl + '/api/customer/' + customer.key + '/registration-requests', {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log(data);
                setCustomerRegistrations(data); // Assuming the response data is an array of customer users
            })
            .catch(error => {
                console.error('Error fetching customers:', error);
            });
    }, [customer.key, token]);

    const createRegistration = async () => {
        fetch(apiUrl + '/api/customer/' + customer.key + '/registration-requests', {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            method: 'POST',
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log(data);
                fetchCustomerRegistrations();
            })
            .catch(error => {
                console.error('Error fetching customers:', error);
            });
    }

    useEffect(() => {
        const f = fetchCustomerRegistrations;
        f();
    }, [fetchCustomerRegistrations]); // Empty dependency array ensures the API call runs only once on component mount

    return (
        <div>
            <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
                <Typography gutterBottom style={{ textAlign: 'right', color: '#777' }}>
                    Device Registrations

                    <IconButton color="inherit" onClick={createRegistration}>
                        <AddIcon />
                    </IconButton>

                    <IconButton color="inherit" onClick={fetchCustomerRegistrations}>
                        <RefreshIcon />
                    </IconButton>
                </Typography>
                <hr style={{ color: '#600' }} /> {/* Added horizontal line */}
                <List>
                    {customerRegistrations.filter(function (registration) {
                        return registration.active === true
                    }).map((registration, index) => (
                        <React.Fragment key={registration.key}>
                            <ListItem key={registration.key}>
                                <ListItemText primary={registration.key} secondary={registration.expires_at} />
                            </ListItem>
                            <QRCodeSVG value={registration.key + ';' + registration.token} />
                            <hr style={{ color: '#600' }} /> {/* Added horizontal line */}
                        </React.Fragment>
                    ))}
                </List>
                <div>App Download
                    <QRCodeSVG value={"https://www.paperknifemobile.com/dd2/dd3/dd3-app-release.apk"} />
                </div>
            </Paper>
        </div>
    );
};

export default CustomerDeviceRegistrations;
