import React from 'react';

function calculateDuration(startTime, endTime) {
    const start = new Date(startTime);
    const end = new Date(endTime);
    const diff = end - start;

    const hours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = Math.floor((diff / (1000 * 60)) % 60);

    return { hours, minutes };
}

function DurationComponent({ startTime, endTime }) {
    const { hours, minutes } = calculateDuration(startTime, endTime);

    return (
        <div>
            {hours}h {minutes}m
        </div>
    );
}

// Example usage:
// <DurationComponent startTime="2022-03-29T10:00:00" endTime="2022-03-29T12:30:00" />

export default DurationComponent;
