import React, { useState, useEffect, useMemo } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Tooltip, Typography } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { makeStyles } from '@mui/styles';
import DurationComponent from './Duration'; // Adjust the path according to your file structure
import MapIcon from '@mui/icons-material/Map';
import MapModal from './MapModal';
import EditableTableCell from './EditableTableCell';
import { DrivingSession, DrivingSessionAnalysis } from '../types'; // Adjust the path to where your types are defined

interface SortConfig {
    key: keyof DrivingSession;
    direction: 'ascending' | 'descending';
}

interface SortableHeaderCellProps {
    children: React.ReactNode;
    sortKey: keyof DrivingSession;
    onSort: (key: keyof DrivingSession) => void;
    sortConfig: SortConfig;
}

interface Coordinate {
    lat: number;
    lng: number;
}

const SortableHeaderCell: React.FC<SortableHeaderCellProps> = ({ children, sortKey, onSort, sortConfig }) => {
    const createSortHandler = () => onSort(sortKey);
    const isSorted = sortConfig.key === sortKey;
    const sortIcon = isSorted ? (sortConfig.direction === 'ascending' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />) : null;

    return (
        <TableCell
            onClick={createSortHandler}
            style={{ cursor: 'pointer' }}
        >
            <Tooltip title="Sort" placement="top" arrow>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {children}
                    {sortIcon}
                </div>
            </Tooltip>
        </TableCell>
    );
};

interface DrivingSessionTableProps {
    customerDrivingSessions: DrivingSession[];
    deleteDrivingSessionFunc: (drivingSessionKey: string) => void;
    getDrivingSessionAnalysisFunc: (drivingSessionKey: string, callbackFunc: (data: DrivingSessionAnalysis) => void) => void;
}

const formatTime = (dateString: Date) => {
    const date = new Date(dateString);
    const years = date.getFullYear().toString().padStart(4, '0');
    const months = (date.getMonth() + 1).toString().padStart(2, '0');
    const days = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${years}-${months}-${days} ${hours}:${minutes}`;
};

const useStyles = makeStyles(theme => ({
    liveIcon: {
        verticalAlign: 'middle',
        color: 'red',
    },
    liveText: {
        color: 'red',
        fontWeight: 'bold',
    },
    offlineText: {
        color: 'gray',
    }
}));


const DrivingSessionTable: React.FC<DrivingSessionTableProps> = ({ customerDrivingSessions, deleteDrivingSessionFunc, getDrivingSessionAnalysisFunc }) => {
    const [sortConfig, setSortConfig] = useState<SortConfig>({ key: "startTime", direction: 'descending' });
    const [modalOpen, setModalOpen] = useState(false);
    const [currentDrivingSessionAnalysis, setCurrentDrivingSessionAnalysis] = useState<DrivingSessionAnalysis>();

    const updateDriverName = async (id: string, newName: string) => {
        // API call to update the driver name
        // Example: axios.put('/api/drivers/' + id, { name: newName })
        // Handle response and errors...
        console.log(id, newName);
    };

    const requestSort = (key: keyof DrivingSession) => {
        let direction: 'ascending' | 'descending' = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const handleDelete = (drivingSessionKey: string) => {
        if (!window.confirm('Are you sure you want to delete this driving session?')) {
            return;
        }
        deleteDrivingSessionFunc(drivingSessionKey);
    }

    const handleMap = (drivingSessionKey: string) => {
        const pathCoordinates: Coordinate[] = [];

        getDrivingSessionAnalysisFunc(drivingSessionKey, (data: DrivingSessionAnalysis) => {
            console.log(data);
            setCurrentDrivingSessionAnalysis(data);
            setModalOpen(true);
        });
    };

    const sortedData = useMemo(() => {
        if (!customerDrivingSessions || customerDrivingSessions.length === 0) {
            return [];
        }

        let sortableItems = [...customerDrivingSessions];
        if (sortConfig && (sortConfig.key === 'driverName' || 'routeName' || sortConfig.key === 'startTime')) {
            sortableItems.sort((a, b) => {
                let aValue = a[sortConfig.key];
                let bValue = b[sortConfig.key];

                // If sorting by string (e.g., driverName)
                if (typeof aValue === 'string' && typeof bValue === 'string') {
                    return sortConfig.direction === 'ascending'
                        ? aValue.localeCompare(bValue)
                        : bValue.localeCompare(aValue);
                }

                // If sorting by date/time (e.g., startTime)
                if (sortConfig.key === 'startTime') {
                    let dateA = new Date(aValue);
                    let dateB = new Date(bValue);
                    return sortConfig.direction === 'ascending'
                        ? dateA.getTime() - dateB.getTime()
                        : dateB.getTime() - dateA.getTime();
                }

                return 0;
            });
        }
        return sortableItems;
    }, [customerDrivingSessions, sortConfig]);

    const [tableHeight, setTableHeight] = useState(0);

    useEffect(() => {
        const TABLE_ROW_HEIGHT = 50; // Adjust as needed
        const MAX_ROWS_TO_DISPLAY = 10;
        const HEADER_ROW_HEIGHT = 50; // Adjust if you have a header row with different height

        const calculateTableHeight = () => {
            // Calculate visible part of the window or container
            const visibleHeight = window.innerHeight - 200; // Adjust 200 to your specific UI design needs

            // Calculate how many rows can be displayed within the visible area
            const visibleRows = Math.min(MAX_ROWS_TO_DISPLAY, Math.floor(visibleHeight / TABLE_ROW_HEIGHT));

            // Set the table height
            setTableHeight((visibleRows * TABLE_ROW_HEIGHT) + HEADER_ROW_HEIGHT);
        };

        // Calculate initial table height
        calculateTableHeight();

        // Add event listener for window resize
        window.addEventListener('resize', calculateTableHeight);

        // Cleanup event listener
        return () => {
            window.removeEventListener('resize', calculateTableHeight);
        };
    }, []);
    const classes = useStyles();

    return (
        <TableContainer component={Paper} style={{ maxHeight: `${tableHeight}px`, overflowY: 'auto' }}>
            <Table stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>
                        <TableCell>Status</TableCell>
                        <SortableHeaderCell sortKey='driverName' onSort={requestSort} sortConfig={sortConfig}>Driver Name</SortableHeaderCell>
                        <SortableHeaderCell sortKey='routeName' onSort={requestSort} sortConfig={sortConfig}>Route</SortableHeaderCell>
                        <SortableHeaderCell sortKey='startTime' onSort={requestSort} sortConfig={sortConfig}>Start Time</SortableHeaderCell>
                        <TableCell>Duration</TableCell>
                        <TableCell>Distance (Km)</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedData.map((item, index) => (
                        <TableRow key={item.drivingSessionKey}>
                            <TableCell>
                                {!item.endTime ? (
                                    <Typography variant="body1" className={classes.liveText}>
                                        <FiberManualRecordIcon className={classes.liveIcon} />
                                        Live
                                    </Typography>
                                ) : (
                                    <Typography variant="body1" className={classes.offlineText}>
                                        Offline
                                    </Typography>
                                )}
                            </TableCell>
                            <TableCell>{item.driverName}</TableCell>
                            <TableCell>{item.routeName}</TableCell>
                            <TableCell>{formatTime(item.startTime)}</TableCell>
                            <TableCell><DurationComponent startTime={item.startTime} endTime={item.endTime || Date()} /></TableCell>
                            <TableCell>{item.distance ? item.distance.toFixed(2) : 0} km</TableCell> {/* Show 'N/A' if distanceTravelledKm is null */}
                            <TableCell>
                                <IconButton
                                    aria-label="map"
                                    onClick={() => handleMap(item.drivingSessionKey)}
                                ><MapIcon />
                                </IconButton>
                                <IconButton
                                    aria-label="delete"
                                    onClick={() => handleDelete(item.drivingSessionKey)}
                                ><DeleteIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <MapModal
                open={modalOpen}
                handleClose={() => setModalOpen(false)}
                analysis={currentDrivingSessionAnalysis}
            />
        </TableContainer>
    );
};

export default DrivingSessionTable;
