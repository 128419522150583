import React, { useEffect, useRef } from 'react';
import 'leaflet/dist/leaflet.css';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css'; // Re-uses images from ~leaflet package
import 'leaflet-defaulticon-compatibility';
import L from 'leaflet';

import { blue } from '@mui/material/colors';

function differenceInMinutes(date1, date2) {
    const diffInMillis = Math.abs(date1.getTime() - date2.getTime());
    return diffInMillis / (1000 * 60);
}

function LeafletMap({ analysis }) {
    const mapContainerRef = useRef(null);

    const stationarySpeedThreshold = .1; // m/s
    const pathSegments = [[]];
    const stationaryPeriods = analysis.stationaryPeriods;
    var stationary = analysis.datapoints[0].speed < stationarySpeedThreshold;
    var index = 0

    // different segments of the path
    for (let i = 0; i < analysis.datapoints.length - 1; i++) {
        if (analysis.datapoints[i].speed > 0.1 && stationary) {
            stationary = false
            pathSegments[index].push([analysis.datapoints[i].latitude, analysis.datapoints[i].longitude])
            index++
            pathSegments.push([])
            pathSegments[index].push([analysis.datapoints[i].latitude, analysis.datapoints[i].longitude])
        } else if (analysis.datapoints[i].speed <= 0.1 && !stationary) {
            stationary = true
            pathSegments[index].push([analysis.datapoints[i].latitude, analysis.datapoints[i].longitude])
            index++
            pathSegments.push([])
            pathSegments[index].push([analysis.datapoints[i].latitude, analysis.datapoints[i].longitude])
        } else {
            pathSegments[index].push([analysis.datapoints[i].latitude, analysis.datapoints[i].longitude])
        }

    }

    const pathCoordinates = analysis.datapoints.map(event => [event.latitude, event.longitude]);


    useEffect(() => {
        if (!mapContainerRef.current) return; // Ensure container ref is available

        // Initialize the map after a short delay
        const timeoutId = setTimeout(() => {
            const map = L.map(mapContainerRef.current).setView(pathCoordinates[0], 13);
            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                maxZoom: 19,
                attribution: '© OpenStreetMap contributors'
            }).addTo(map);

            var red = true
            pathSegments.forEach(segment => {
                if (red) {
                    L.polyline(segment, { color: 'red' }).addTo(map);
                    red = false
                } else {
                    L.polyline(segment, { color: 'blue' }).addTo(map);
                    red = true
                }
            })


            console.log("got " + stationaryPeriods.length + " stationary periods");
            console.log(stationaryPeriods);
            stationaryPeriods.forEach(period => {
                console.log(period);
                console.log(period.endDatapoint.emittedAt - period.startDatapoint.emittedAt);

                const endTime = new Date(period.endDatapoint.emittedAt);
                const startTime = new Date(period.startDatapoint.emittedAt);
                const minutesDiff = differenceInMinutes(endTime, startTime);



                L.marker([period.startDatapoint.latitude, period.startDatapoint.longitude])
                    .addTo(map)
                    .bindPopup("Stationary period for " + Math.floor(minutesDiff) + " minutes");
            });


            L.marker(pathCoordinates[0]).addTo(map)
                .bindPopup('Starting point<br/>' + analysis.datapoints[0].emittedAt);

            map.invalidateSize(); // Invalidate the size to ensure the map renders correctly
        }, 100);

        return () => {
            clearTimeout(timeoutId); // Clear the timeout if the component unmounts
            if (mapContainerRef.current && mapContainerRef.current._leaflet) {
                mapContainerRef.current.remove(); // Clean up the map if it was initialized
            }
        };
    }, [pathCoordinates]);

    return <div ref={mapContainerRef} style={{ height: '100%', width: '100%' }} />;
}


export default LeafletMap;
