import React, { useState, useEffect } from 'react';
import { Paper, Typography, IconButton, TextField } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import { AuthSession, DrivingSession, DrivingSessionAnalysis } from '../types';

// Icons
import RefreshIcon from '@mui/icons-material/Refresh';
import DrivingSessionTable from './DrivingSessionTable';


// app constants
const apiUrl = process.env.REACT_APP_API_HOST; // Access the environment variable

const CustomerDrivingSessions: React.FC<AuthSession> = ({ customer, token }) => {

    const [customerDrivingSessions, setCustomerDrivingSessions] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value.toLowerCase());
    };

    const filteredDrivingSessions = customerDrivingSessions.filter(
        (session: DrivingSession) => session.driverName.toLowerCase().includes(searchQuery) || session.routeName.toLowerCase().includes(searchQuery)
    );

    const handleDeleteSession = (drivingSessionKey: string) => {
        fetch(apiUrl + '/api/customer/' + customer.key + '/driving-sessions/' + drivingSessionKey, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        }).then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            fetchCustomerDrivingSessions();
        }).catch(error => {
            console.error('Error deleting driving sessions', error);
        })
    }

    const getSessionAnalysis = async (drivingSessionKey: string, callbackFunc: (analysis: DrivingSessionAnalysis) => void) => {
        var uri = apiUrl + '/api/customer/' + customer.key + '/driving-sessions/' + drivingSessionKey + '/analysis';
        fetch(uri, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                callbackFunc(data)
            })
            .catch(error => {
                console.error('Error fetching driving session events', error);
            })
    }


    // Function to fetch the customer driver list
    const fetchCustomerDrivingSessions = async () => {
        fetch(apiUrl + '/api/customer/' + customer.key + '/driving-sessions', {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setCustomerDrivingSessions(data); // Assuming the response data is an array of customer drivers
            })
            .catch(error => {
                console.error('Error fetching customers:', error);
            });
    };

    useEffect(() => {
        fetchCustomerDrivingSessions(); // Call the API function
    }, []); // Empty dependency array ensures the API call runs only once on component mount


    const handleRefresh = () => {
        fetchCustomerDrivingSessions();
    }

    return (
        <div>
            <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
                <Typography gutterBottom style={{ textAlign: 'right', color: '#777' }}>
                    Driving Sessions
                    <TextField
                        label="Search by Driver or Route"
                        variant="outlined"
                        size="small"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        style={{ marginRight: '10px', marginLeft: '20px' }}
                    />
                    <IconButton onClick={handleRefresh} color="inherit">
                        <RefreshIcon />
                    </IconButton>
                </Typography>
                <hr style={{ color: '#600' }} /> {/* Added horizontal line */}
                <DrivingSessionTable customerDrivingSessions={filteredDrivingSessions} deleteDrivingSessionFunc={handleDeleteSession} getDrivingSessionAnalysisFunc={getSessionAnalysis} />
            </Paper>

        </div>
    );
};

export default CustomerDrivingSessions;
