import React, { useState, useEffect } from 'react';
import {
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
} from '@mui/material';

const AddCustomerUserDialog = ({ customer, token, open, handleClose, fetchUsers }) => {
    const [username, setUsername] = useState('');
    const [usernameErrorMessage, setUsernameErrorMessage] = useState('');
    const [email, setEmail] = useState('');
    const [emailErrorMessage, setEmailErrorMessage] = useState('');
    const [password, setPassword] = useState('');
    const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
    const [responseErrorMessage, setResponseErrorMessage] = useState(''); // State to manage error messages

    const apiUrl = process.env.REACT_APP_API_HOST; // Access the environment variable

    useEffect(() => {
        // Reset state when the dialog is closed
        if (!open) {
            setUsername('');
            setEmail('');
            setPassword('');
            setUsernameErrorMessage('');
            setEmailErrorMessage('');
            setPasswordErrorMessage('');
            setResponseErrorMessage('');

        }
    }, [open]);

    const handleUsernameChange = (event) => {
        if (event.target.value.length < 3) {
            setUsernameErrorMessage('Name should be at least 3 characters');
        } else {
            setUsernameErrorMessage('');
        }
        const newUsername = event.target.value;
        setUsername(newUsername);
    };

    const handleEmailChange = (event) => {
        if (!event.target.value.includes('@')) {
            setEmailErrorMessage('Invalid email');
        } else {
            setEmailErrorMessage('');
        }
        const newEmail = event.target.value;
        setEmail(newEmail);
    };

    const handlePasswordChange = (event) => {
        if (event.target.value.length < 8) {
            setPasswordErrorMessage('Password should be at least 8 characters');
        } else {
            setPasswordErrorMessage('');
        }

        const newPassword = event.target.value;
        setPassword(newPassword);
    };

    const canSubmit = () => {
        return usernameErrorMessage === ''
            && emailErrorMessage === ''
            && passwordErrorMessage === '';
    }

    const handleAdd = () => {
        if (canSubmit()) {
            // Perform add action or API call here
            handleSave();
        } else {
            setUsernameErrorMessage('Name should be at least 3 characters');
        }
    };


    const handleSave = () => {
        const userData = {
            username: username,
            email: email,
            password: password,
        };

        fetch(apiUrl + '/api/customer/' + customer.key + '/users', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                // Add any other headers needed by your API
            },
            body: JSON.stringify(userData),
        }).then(response => {
            console.log('status: ' + response.status);

            if (response.ok) {
                // Customer created successfully
                fetchUsers();
                handleClose();
            } else if (response.status == 409) {
                setResponseErrorMessage('Could not create user - email or username is in use.'); // Set error message
            } else {
                console.log('status: ' + response.status);
                setResponseErrorMessage('Failed to create user. Please try again.'); // Set error message
            }

        }).catch(error => {
            console.log('general error');

            // Handle network errors or other issues with the request
            // Display an error message or handle the error appropriately
        });
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Add New User</DialogTitle>
            <DialogContent style={{ width: '300px' }}>
                {responseErrorMessage != '' && (
                    <Typography variant="body2" color="error" gutterBottom>
                        {responseErrorMessage}
                    </Typography>
                )}

                <TextField margin="normal" required fullWidth id="username" label="Username" value={username} onChange={handleUsernameChange} helperText={usernameErrorMessage} />
                <TextField margin="normal" required fullWidth id="email" label="Email" value={email} onChange={handleEmailChange} helperText={emailErrorMessage} />
                <TextField margin="normal" required fullWidth id="password" label="Password" type="password" value={password} onChange={handlePasswordChange} helperText={passwordErrorMessage} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleAdd} variant="contained" color="secondary" disabled={!canSubmit()}>
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddCustomerUserDialog;
