import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Customer from './Customer'; // Import CustomerList component
import { AppBar, Toolbar, Typography, Button, IconButton, Container } from '@mui/material'; // Import Material-UI components
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';

const apiUrl = process.env.REACT_APP_API_HOST; // Access the environment variable


const CustomerDashboard = ({ toggleTheme, darkMode }) => {
    const [customer, setCustomer] = useState(null); // State to store customer data
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const token = params.get('token');
    const customerId = params.get('customerId');

    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('authToken'); // Clear token from storage
        navigate('/'); // Redirect to the login page
    };


    useEffect(() => {
        const fetchCustomer = async () => {
            try {
                const response = await fetch(apiUrl + '/api/customer/' + customerId, {
                    headers: {
                        'Authorization': `Bearer ${token}`, // Assuming token is required for authentication
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                setCustomer(data); // Set the customer data in state
            } catch (error) {
                console.error('There was a problem with the fetch operation:', error);
                handleLogout(); // Handle errors, for instance, logging out the user
            }
        };

        if (token) {
            fetchCustomer(); // Fetch customer data if the token exists
        } else {
            handleLogout();
        }
    }, [token, customerId]); // Dependency array, ensures effect runs once when the component mounts

    // Validate the token
    if (!token) {
        handleLogout(); // Redirect to the login page if token is not valid
        return null; // Return null to prevent rendering the rest of the component
    }



    return (
        <div>
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        DD3 Customer Dashboard
                    </Typography>
                    <IconButton onClick={toggleTheme} color="inherit">
                        {/* Icons for toggling themes */}
                        {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
                    </IconButton>
                    <Button color="inherit" onClick={handleLogout}>Logout</Button>
                </Toolbar>
            </AppBar>

            <Container>
                {customer && (<Customer token={token} customer={customer} />)}
            </Container>
        </div>
    );
};

export default CustomerDashboard;
