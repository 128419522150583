import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { createTheme, ThemeProvider, CssBaseline, IconButton } from '@mui/material';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { lightTheme, darkTheme } from './themes/customThemes';
import Login from './components/Login';
import AdminDashboard from './components/AdminDashboard';
import CustomerDashboard from './components/CustomerDashboard';

const App = () => {
  const [darkMode, setDarkMode] = useState(false);

  const theme = createTheme(darkMode ? darkTheme : lightTheme);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode); // Toggle the dark mode state
  };


  console.log('Dark mode:', darkMode); // Verify state change in the console

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/admin-dashboard" element={<AdminDashboard toggleTheme={toggleDarkMode} darkMode={darkMode} />} /> {/* Admin dashboard route */}
          <Route path="/dashboard" element={<CustomerDashboard toggleTheme={toggleDarkMode} darkMode={darkMode} />} /> {/* Customer dashboard route */}
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
