import React, { useState } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import CustomerList from './CustomerList'; // Import CustomerList component
import { AppBar, Toolbar, Typography, Button, IconButton, Container } from '@mui/material'; // Import Material-UI components
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';


const AdminDashboard = ({ toggleTheme, darkMode }) => {
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const token = params.get('token');

    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('authToken'); // Clear token from storage
        navigate('/'); // Redirect to the login page
    };

    // Validate the token
    if (!token) {
        handleLogout(); // Redirect to the login page if token is not valid
        return null; // Return null to prevent rendering the rest of the component
    }

    return (
        <div>
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        DD3 Admin Dashboard
                    </Typography>
                    <IconButton onClick={toggleTheme} color="inherit">
                        {/* Icons for toggling themes */}
                        {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
                    </IconButton>
                    <Button color="inherit" onClick={handleLogout}>Logout</Button>
                </Toolbar>
            </AppBar>

            <Container>
                <CustomerList token={token} />
            </Container>
        </div>
    );
};

export default AdminDashboard;
