import React, { useState, useEffect, useCallback } from 'react';
import { Paper, Typography, IconButton } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleOutlineTwoToneIcon from '@mui/icons-material/CheckCircleOutlineTwoTone';
import RefreshIcon from '@mui/icons-material/Refresh';
import EditableTableCell from './EditableTableCell';

// Assuming these are the types for your customer and token props
interface Customer {
    key: string;
    name: string;
    preferredTimeZone: string;
}

interface Device {
    key: string;
    name: string;
    make: string;
    model: string;
    brand: string;
    enabled: boolean;
    updatedAt: Date;
}

interface UpdatableDevice {
    name: string;
    make: string;
    model: string;
    brand: string;
    enabled: boolean;
    lastUpdatedAt: Date;
}

interface DeviceUpdateConflictResponse {
    type: string;
    message: string;
    currentResource: Device;
}

interface CustomerDevicesProps {
    customer: Customer;
    token: string;
}

const CustomerDevices: React.FC<CustomerDevicesProps> = ({ customer, token }) => {
    const [customerDevices, setCustomerDevices] = useState<Device[]>([]);

    const apiUrl = process.env.REACT_APP_API_HOST; // Access the environment variable

    const updateDeviceName = async (device: Device, newName: string) => {
        console.log(device.key, newName);

        // create an updatable device object with the new name but the same values for the other properties
        const updatedDevice: UpdatableDevice = {
            name: newName,
            make: device.make,
            model: device.model,
            brand: device.brand,
            enabled: device.enabled,
            lastUpdatedAt: device.updatedAt,
        };

        // optimistically update the UI
        setCustomerDevices(customerDevices.map(d =>
            d.key === device.key ? { ...d, name: newName } : d
        ));


        // API call to update the device name
        // Example: axios.put('/api/devices/' + id, { name: newName })
        doUpdateDevice(device, updatedDevice);
    };

    const handleDelete = async (deviceKey: string) => {
        if (!window.confirm('Are you sure you want to remove this device from your account? You can re-register it later.')) {
            return;
        }

        try {
            const response = await fetch(`${apiUrl}/api/customer/${customer.key}/devices/${deviceKey}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            fetchCustomerDevices();
        } catch (error) {
            console.error('Error deleting device', error);
        }
    };

    const handleToggle = async (device: Device) => {
        const updatedDevice: UpdatableDevice = {
            name: device.name,
            make: device.make,
            model: device.model,
            brand: device.brand,
            enabled: !device.enabled,
            lastUpdatedAt: device.updatedAt, // Ensure this is managed correctly for optimistic concurrency
        };

        // optimistically update the UI
        setCustomerDevices(customerDevices.map(d =>
            d.key === device.key ? { ...d, enabled: !d.enabled } : d
        ));

        doUpdateDevice(device, updatedDevice);
    }


    const doUpdateDevice = async (device: Device, updatedDevice: UpdatableDevice) => {
        // API call to update the device
        try {
            const response = await fetch(`${apiUrl}/api/customer/${customer.key}/devices/${device.key}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedDevice),
            });

            // If the device was updated by another user, handle the conflict by updating the UI
            // with the current state of the device (retrieved from the response) and informing the user
            if (response.status === 409) {
                const conflictResponse: DeviceUpdateConflictResponse = await response.json();
                const originalDeviceState = conflictResponse.currentResource;
                setCustomerDevices(customerDevices.map(d =>
                    d.key === originalDeviceState.key ? originalDeviceState : d
                ));
                alert('The device was updated by another user. Your changes have been reverted.');
            } else if (!response.ok) {
                setCustomerDevices(customerDevices);
                throw new Error(`Failed to update the device: ${response.status}`);
            } else {
                // Handle successful response
                fetchCustomerDevices();
            }
        } catch (error) {
            setCustomerDevices(customerDevices);
        }
    };

    const fetchCustomerDevices = useCallback(async () => {
        try {
            const response = await fetch(`${apiUrl}/api/customer/${customer.key}/devices`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setCustomerDevices(data);
        } catch (error) {
            console.error('Error fetching devices:', error);
        }
    }, [customer.key, token]);

    useEffect(() => {
        fetchCustomerDevices();
    }, [fetchCustomerDevices]);

    return (
        <div>
            <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
                <Typography gutterBottom style={{ textAlign: 'right', color: '#777' }}>
                    Devices
                    <IconButton color="inherit" onClick={fetchCustomerDevices}>
                        <RefreshIcon />
                    </IconButton>
                </Typography>
                <hr style={{ color: '#600' }} />
                <TableContainer component={Paper} style={{ overflowY: 'auto' }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Make</TableCell>
                                <TableCell>Model</TableCell>
                                <TableCell>Brand</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {customerDevices.map((device) => (
                                <TableRow key={device.key + '-' + device.enabled}>
                                    <EditableTableCell
                                        value={device.name}
                                        onChange={(newName) => updateDeviceName(device, newName)}
                                    />
                                    <TableCell>{device.make}</TableCell>
                                    <TableCell>{device.model}</TableCell>
                                    <TableCell>{device.brand}</TableCell>
                                    <TableCell>
                                        <IconButton
                                            aria-label="active"
                                            onClick={() => handleToggle(device)}
                                        >
                                            <CheckCircleOutlineTwoToneIcon
                                                style={{ color: device.enabled ? 'green' : 'default' }}
                                            />
                                        </IconButton>
                                        <IconButton
                                            aria-label="delete"
                                            onClick={() => handleDelete(device.key)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    );
};

export default CustomerDevices;
