import React from 'react';
import { Modal, Box } from '@mui/material';
import LeafletMap from './LeafletMap';

function MapModal({ open, handleClose, analysis }) {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%', // Set a fixed width or use a percentage
        height: '80%', // Set a fixed height or use a percentage
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };



    return (
        <Modal id="modal" open={open} onClose={handleClose}>
            <Box id="box" sx={style}>
                <LeafletMap analysis={analysis} />
            </Box>
        </Modal>
    );
}

export default MapModal;