// EditableTableCell.tsx
import React, { useState } from 'react';
import TableCell from '@mui/material/TableCell';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';

type EditableTableCellProps = {
    value: string;
    onChange: (newValue: string) => void;
};

const EditableTableCell: React.FC<EditableTableCellProps> = ({ value, onChange }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [cellValue, setCellValue] = useState(value || 'N/A');

    const handleClick = () => {
        setIsEditing(true);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCellValue(event.target.value);
    };

    const handleBlur = () => {
        setIsEditing(false);
        onChange(cellValue);
    };

    return (
        <TableCell component="th" scope="row" onClick={handleClick}>
            {isEditing ? (
                <TextField
                    value={cellValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoFocus
                    fullWidth
                />
            ) : (
                cellValue
            )}
        </TableCell>
    );
};

export default EditableTableCell;
