import React, { useState, useEffect } from 'react';
import { Paper, Typography, List, ListItem, ListItemText, IconButton } from '@mui/material';

// Icons
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';

import AddCustomerDriverDialog from './dialogs/AddCustomerDriverDialog';

// app constants
const apiUrl = process.env.REACT_APP_API_HOST; // Access the environment variable

const Drivers = ({ customer, token }) => {

    const [customerDrivers, setCustomerDrivers] = useState([]);
    const [isCreateDriverDialogOpen, setCreateDriverDialogOpen] = useState(false);

    const handleAddDriverDialogOpen = () => { setCreateDriverDialogOpen(true); };
    const handleAddDriverDialogClose = () => { setCreateDriverDialogOpen(false); };

    // Function to fetch the customer driver list
    const fetchCustomerDrivers = async () => {
        fetch(apiUrl + '/api/customer/' + customer.key + '/drivers', {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setCustomerDrivers(data); // Assuming the response data is an array of customer drivers
            })
            .catch(error => {
                console.error('Error fetching customers:', error);
            });
    };

    useEffect(() => {
        fetchCustomerDrivers(); // Call the API function
    }, []); // Empty dependency array ensures the API call runs only once on component mount


    const handleRefresh = () => {
        fetchCustomerDrivers();
    }

    return (
        <div>
            <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
                <Typography gutterBottom style={{ textAlign: 'right', color: '#777' }}>
                    Drivers
                    <IconButton onClick={handleAddDriverDialogOpen} color="inherit">
                        <AddIcon />
                    </IconButton>
                    <IconButton onClick={handleRefresh} color="inherit">
                        <RefreshIcon />
                    </IconButton>
                </Typography>
                <hr style={{ color: '#600' }} /> {/* Added horizontal line */}
                <List>
                    {customerDrivers.map((driver, index) => (
                        <React.Fragment key={driver.key}>
                            <ListItem key={driver.key}>
                                <ListItemText primary={driver.name} />
                            </ListItem>
                            <hr style={{ color: '#600' }} /> {/* Added horizontal line */}
                        </React.Fragment>
                    ))}
                </List>
            </Paper>
            <AddCustomerDriverDialog customer={customer} token={token} open={isCreateDriverDialogOpen} handleClose={handleAddDriverDialogClose} fetchDrivers={fetchCustomerDrivers} />
        </div>
    );
};

export default Drivers;
